import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMembers(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/teamMemberList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMember(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/teamMemberById', { params: { id }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCounter() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/teamCounterGet')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMember(_, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/teamMemberCreate', { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMember(_, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/teamMemberUpdate', { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCounter(_, counter) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/teamCounterUpdate', { ...counter })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMember(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/teamMemberDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/teamMember', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
