<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Media</h3>
            </b-col>
            <b-col sm="12">
              <b-row>
                <b-col sm="12">
                  <div 
                    class="flex flex-col preview-image dashed"
                    :hidden="fileUrl !== ''"
                    id="dropzone"
                    :class="loading ? 'pointer-none' : ''"
                  >
                    <div
                      class="flex flex-col items-center"
                      @click="$refs.file.$refs.input.click()"
                    >
                      <feather-icon
                        icon="UploadCloudIcon"
                        size="30"
                      />
                      <span class="font-18px">Select your image</span>
                      <span>Recommened dimension : 1440x740 px</span>
                      <span>Image: Max 1 MB (PNG, JPG, MP4)</span>
                    </div>
                  </div>
                  <b-img
                    :src="fileUrl"
                    :hidden="fileUrl === ''"
                    class="image-full cursor-pointer avatar"
                    @click="$refs.file.$refs.input.click()"
                  />
                </b-col>
                <b-col sm="12" class="mt-12px">
                  <b-form-group>
                    <label for="member-image">Image Upload <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      ref="image"
                      name="image"
                      rules="required"
                    >
                      <b-form-file
                        ref="file"
                        :disabled="loading"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose another file"
                        accept=".jpg, .png"
                        @input="inputImageRenderer"
                        :class="loading ? 'loading-form-file': ''"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Content</h3>
            </b-col>
            <b-col sm="12">
              <b-tabs pills>
                <b-tab title="English" active>
                  <b-form-group>
                    <label for="content-member-firstname">Firstname <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        v-model="content.english.firstname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Firstname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-lastname">Lastname <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        v-model="content.english.lastname"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Lastname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-position">Position</label>
                    <b-form-input
                      v-model="content.english.position"
                      placeholder="Position"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Thai">
                  <b-form-group>
                    <label for="content-member-firstname">Firstname</label>
                    <b-form-input
                      v-model="content.thai.firstname"
                      placeholder="Firstname"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-lastname">Lastname</label>
                    <b-form-input
                      v-model="content.thai.lastname"
                      placeholder="Lastname"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-position">Position</label>
                    <b-form-input
                      v-model="content.thai.position"
                      placeholder="Position"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Chinese">
                  <b-form-group>
                    <label for="content-member-firstname">Firstname</label>
                    <b-form-input
                      v-model="content.chinese.firstname"
                      placeholder="Firstname"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-lastname">Lastname</label>
                    <b-form-input
                      v-model="content.chinese.lastname"
                      placeholder="Lastname"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-member-position">Position</label>
                    <b-form-input
                      v-model="content.chinese.position"
                      placeholder="Position"
                    />
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Status</h3>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <label for="status">Status <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <v-select
                    v-model="status"
                    :state="errors.length > 0 ? false : null"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <div class="fixed-footer">
      <div>
        <b-button
          variant="danger-outline"
          @click="confirmDelete(id, content.english.businessUnit)"
        >
          <feather-icon
            icon="Trash2Icon"
            size="16"
            class="mr-50"
          />
          <span>Delete</span>
        </b-button>
      </div>
      <div>
        <b-button
          variant="outline-secondary"
          :to="{ name: 'member-list'}"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="ml-1"
          variant="success"
          type="submit"
          :disabled="loading"
          @click.prevent="validationForm"
        >
          <span>Save</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Dropzone from 'dropzone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormRadioGroup, BFormRadio, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTab, BTabs, BFormTextarea } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useMemberEdit from './useMemberEdit'
import memberStoreModule from '../teamStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      id: '',
      loading: false,
      file: null,
      fileUrl: '',
      status: 'Activate',
      content: {
        thai: {
          firstname: '',
          lastname: '',
          position: '',
        },
        english: {
          firstname: '',
          lastname: '',
          position: '',
        },
        chinese: {
          firstname: '',
          lastname: '',
          position: '',
        },
      },
      avoidRouteLeave: false,
    }
  },
  methods: {
    async inputImageRenderer(file) {
      if (!file) return;
      if ((this.file.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append('files', file);
      
      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error upload image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }

      this.fileUrl = data[0];
      this.loading = false;
    },
    async validationForm() {
      const isValid = await this.$refs.form.validate()
      if (!isValid) return
      if ((this.file.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const { status } = await this.updateMember({
        id: this.id,
        status: this.status,
        content: this.content,
        memberImg: this.fileUrl,
      })
      if (status !== 200) {
        this.makeToast(false)
        return;
      }

      this.avoidRouteLeave = true
      this.$router
        .push({ name: 'member-list' })
        .then(() => {
          this.makeToast(true)
        })
    },
    confirmDelete(id, name) {
      this.$swal({
        title: 'Are you sure to delete image?',
        text: 'This will permanently erase your information. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const { status } = await this.deleteMember(id);
        if (status !== 200) {
          this.makeToast(false, name)
          return;
        }

        this.avoidRouteLeave = true
        this.$router
          .push({ name: 'member-list' })
          .then(() => {
            this.makeDelateToast(true, name)
          })
      })
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Update succeeded!' : 'Update failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `Member has been updated.` : 'Can not update member.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
    makeDelateToast(success, name) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Delete succeeded!' : 'Delete failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `Member has been deleted.` : 'Can not delete member.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  beforeRouteLeave(_, from, next) {
    if (this.avoidRouteLeave) {
      next()
      return
    }

    this.$swal({
      title: "Are you sure to leave?",
      text: "Do you really want to leave? you have unsaved changes!",
      icon: "warning",
      iconColor: "#FF9F43",
      showCancelButton: true,
      confirmButtonText: "Leave",
      customClass: {
        htmlContainer: "my-50",
        actions: "flex-row-reverse my-1",
        confirmButton: "btn btn-danger px-5",
        cancelButton: "btn btn-outline-secondary px-5 mr-1",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (!result.value) {
        next(false);
      } else {
        next()
      }
    });
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }
    try {
      const id = this.$router.currentRoute.params.id;
      const { data } = await this.fetchMember(id);
      this.id = id;
      this.status = data.status;
      this.content = data.content;
      this.fileUrl = data.memberImg;
      this.file = new File([''], 'banner.jpg');
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching member',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  mounted() {
    const dropzoneElement = document.getElementById('dropzone')
    if (dropzoneElement) {
      const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.file.setFiles([file])
      })
    }
  },
  setup() {
    const GALLERY_APP_STORE_MODULE_NAME = 'app-member'

    // Register module
    if (!store.hasModule(GALLERY_APP_STORE_MODULE_NAME)) store.registerModule(GALLERY_APP_STORE_MODULE_NAME, memberStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GALLERY_APP_STORE_MODULE_NAME)) store.unregisterModule(GALLERY_APP_STORE_MODULE_NAME)
    })

    const { 
      toast,
      fetchMember,
      updateMember,
      deleteMember,
      uploadImage,
      statusOptions,
    } = useMemberEdit()

    return {
      toast,
      fetchMember,
      updateMember,
      deleteMember,
      uploadImage,
      statusOptions,
    }
  },
}
</script>
<style lang="scss" scoped>
.avatar {
  width: 120px;
  height: 120px;
}
</style>